import Keycloak from 'keycloak-js'

let clientId = ''

if (process.env.NODE_ENV === 'production' && process.env.API === 'tenant') {
  clientId = 'backoffice_tenant_prod'
} else if (process.env.NODE_ENV === 'production' && process.env.API === 'services') {
  clientId = 'backoffice_prod'
} else {
  clientId = 'backoffice_local'
}

const keycloak = new Keycloak({
  realm: 'noie-associates',
  url: process.env.NODE_ENV === 'production' ? 'https://identity.noie.com/' : 'https://identity-staging.noie.com/',
  clientId,
})

export default keycloak
